import constants from '../constants';

const drawerItems = [
        {
          module: constants.MODULE_DASHBOARD,
          icon: 'mdi-view-dashboard',
          title: 'dashboard',
          to: '/pages/dashboard',
        },
        {
          module: constants.MODULE_USER_PROFILE,
          icon: 'mdi-account',
          title: 'My Profile',
          to: '/user',
        },
        {
          module: constants.MODULE_COMPANIES,
          icon: 'mdi-domain',
          title: 'Companies',
          to: '/companies',
        },
        {
          module: constants.MODULE_SETTINGS,
          title: 'Settings',
          icon: 'mdi-cog-outline',
          to: '/feature-settings',
        },
        {
          module: constants.MODULE_SERVICE_PROVIDERS,
          icon: 'mdi-account-group',
          title: 'Service Providers',
          to: '/service-providers',
        },
        {
          module: constants.MODULE_SITES,
          icon: 'mdi-office-building-marker',
          title: 'My Sites',
          to: '/locations',
        },
        {
          module: constants.MODULE_WORK_ORDERS,
          icon: 'mdi-sitemap-outline',
          title: 'Work Orders',
          to: '/work-orders',
        },
        {
          module: constants.MODULE_RECORDS,
          icon: 'mdi-account-multiple-check',
          title: 'Records',
          to: '/records',
        },
        {
          module: constants.MODULE_WIDGETS,
          title: 'Widgets',
          icon: 'mdi-apps',
          to: '/widgets',
        },
        {
          module: constants.MODULE_SUPPORT,
          title: 'Support',
          icon: 'mdi-face-agent',
          to: '/support',
        },
    ];

export default drawerItems;
